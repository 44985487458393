import { Card, InfoCard } from '@popsure/dirty-swan';
import { FilledLinkToWebField } from '@prismicio/types';
import classNames from 'classnames';
import CtaLink from 'components/CtaLink';
import MissingRequiredFields from 'components/MissingRequiredFields';
import PrismicRichText from 'components/PrismicRichText';
import SectionHeader from 'components/SectionHeaderSlice';
import { IconTile } from 'content/household-insurance/components/WhatsCoveredSection/IconTile';
import Image from 'next/image';
import React from 'react';
import { InfoCardSectionSlice } from 'types.generated';

import styles from './InfoCardSection.module.scss';

export const InfoCardSection = ({ slice }: { slice: InfoCardSectionSlice }) => {
  const {
    primary: {
      has_background_color: hasBackgroundColor,
      title,
      desktop_alignment: desktopAlignment,
      mobile_alignment: mobileAlignment,
      underline,
      column_count: columnCount = 2,
      bottom_link: bottomLink,
      bottom_link_text: bottomLinkText,
    },
    items,
    variation,
  } = slice;

  if (!title || !items.length) return <MissingRequiredFields />;

  return (
    <section
      className={classNames('mt56', styles.container, {
        [styles.background]: hasBackgroundColor,
        py72: hasBackgroundColor,
      })}
    >
      <div className="p-body d-flex fd-column jc-center">
        <SectionHeader
          title={title}
          desktopAlignment={desktopAlignment}
          mobileAlignment={mobileAlignment}
          underline={underline}
        />
        <div className={`mt32 ${styles[`column-${columnCount}`]}`}>
          {items.map((item) => {
            if (!item.card_title || !item.card_icon.url) return;
            return (
              <div key={item.card_title} className="my16">
                {
                  {
                    default: (
                      <InfoCard
                        className={styles.cardContainer}
                        title={item.card_title}
                        topIcon={{
                          src: item.card_icon.url,
                          alt: item.card_icon.alt ?? '',
                        }}
                        state="static"
                      >
                        {item.card_description_richtext?.length ? (
                          <div className="d-block ta-center">
                            <PrismicRichText
                              richText={item.card_description_richtext}
                              paragraphClassName="p-p tc-grey-600"
                            />
                          </div>
                        ) : (
                          <span className="p-p d-block ta-center tc-grey-600">
                            {item.card_description}
                          </span>
                        )}
                      </InfoCard>
                    ),
                    withTopLeftIcon: (
                      <Card
                        title={item.card_title}
                        titleVariant="medium"
                        dropShadow={false}
                        classNames={{ icon: styles.topLeftIcon }}
                        icon={
                          <Image
                            src={item.card_icon.url}
                            alt={item.card_icon.alt ?? ''}
                            width={28}
                            height={28}
                          />
                        }
                        description={
                          item.card_description_richtext?.length ? (
                            <PrismicRichText
                              richText={item.card_description_richtext}
                              paragraphClassName="p-p tc-grey-600"
                            />
                          ) : (
                            <span className="p-p tc-grey-600">
                              {item.card_description}
                            </span>
                          )
                        }
                      />
                    ),
                    withLargeIcon: (
                      <div className="d-flex mx16">
                        <IconTile
                          title={item.card_title}
                          iconSrc={item.card_icon.url}
                          iconAlt={item.card_icon.alt ?? ''}
                        >
                          {item.card_description_richtext?.length ? (
                            <PrismicRichText
                              richText={item.card_description_richtext}
                              paragraphClassName={styles.withLargeIconParagraph}
                            />
                          ) : (
                            item.card_description
                          )}
                        </IconTile>
                      </div>
                    ),
                  }[variation]
                }
              </div>
            );
          })}
        </div>
        {bottomLink.link_type === 'Web' && bottomLinkText && (
          <CtaLink
            href={(bottomLink as FilledLinkToWebField).url}
            className="p-a p-p fw-bold c-pointer bg-transparent my24 mx-auto"
          >
            {bottomLinkText}
          </CtaLink>
        )}
      </div>
    </section>
  );
};
