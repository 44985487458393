import Markdown from 'components/markdown';
import Image, { StaticImageData } from 'next/image';

import styles from './IconTile.module.scss';

export const IconTile = ({
  title,
  iconSrc,
  iconAlt,
  children,
}: {
  title: string;
  iconSrc: string | StaticImageData;
  iconAlt: string;
  children: React.ReactNode | string;
}) => (
  <div className={`d-flex fd-column ai-center jc-start ${styles.container}`}>
    <Image src={iconSrc} alt={iconAlt} width={152} height={152} />
    <div>
      <h3 className="p-h4 w100 ta-center mt16">{title}</h3>
      {typeof children === 'string' ? (
        <Markdown className={styles.markdownOverride}>{children}</Markdown>
      ) : (
        children
      )}
    </div>
  </div>
);
